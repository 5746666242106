<template>
  <div class="fill-default login--container" v-loading="loadingPageLogin">
    <!-- Header -->
    <!-- <div class="header  py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div> -->
    <!-- Page content -->
    <!-- <div class="sign--container">
      <div class="sign-left">
        <div class="form-container">
        </div>
      </div>
      <div class="sign--content">
        <div class="mt-4" style="min-width: 70%;">
          <div class="card mb-0" v-loading="loading">
            <div class="card-header bg-transparent pb-3">
              <div class="text-muted text-center mt-1 mb-2">
                <div class="text-center">
                  <router-link to="/">
                  </router-link>
                  <p class="mb-2 mt--2 nb--title">Wring</p>
                </div>
              </div>
              <div data-netlify-identity-menu></div>
              <div class="btn-wrapper text-center">
                <small class="sm-cmpl" >Complete Coverage Now</small>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-3">
              <div class="text-center text-muted mb-2">
                <a :href="keycloakUrl" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="../../assets/images/keycloak.svg"/></span>
                  <span class="btn-inner--text">Sign in or create an account</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script
  type="text/javascript"
  src="https://identity.netlify.com/v1/netlify-identity-widget.js"
></script>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    email: "",
    eventCode: "",
    password: "",
    googleUrl:
      process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "google",
    githubUrl:
      process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "github",
    keycloakUrl: `${process.env.VUE_APP_API_URL_PREFIX}/auth/v1/oauth2/keycloak`,
    loadingPageLogin: true,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      const user = {
        email: this.email,
        password: this.password,
      };

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      // await axios
      // .get(`${process.env.VUE_APP_API_URL_PREFIX}/auth/v1/oauth2/keycloak`)
      // .then((response) => {
      //   console.log("response", response)
      // })
      try {
        const logged = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });

        if (logged) {
          // const errorMessage = logged.message ?? (logged.data && logged.data.message) ?? 'please contact support'
          const tmpErrorMessage =
            "Please check your email and password. If you recently signed up, please click on the verification link in the email we sent to complete account creation";
          this.$notify({
            type: "danger",
            message: tmpErrorMessage,
          });
        }
      } catch (error) {
        if (error) {
          this.$notify({
            type: "danger",
            message: error,
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Login failed! Server may be busy, try again later",
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    window.location.replace(
      `${process.env.VUE_APP_API_URL_PREFIX}/auth/v1/oauth2/keycloak`
    );
    const errorTxt = localStorage.getItem("login--message");
    if (errorTxt) {
      this.$notify({
        type: "danger",
        message: `You have been logged out : ${errorTxt}`,
      });

      localStorage.removeItem("login--message");
    }
  },
};
</script>

<style lang="scss">
.sm-cmpl {
  font-size: 1.4em;
  font-weight: 600;
  color: #5e72e4;
}

.form-container {
  text-align: center;
  img {
    width: 80%;
    border-radius: 5px;
    filter: blur(1px);
  }
}
.header-login {
  padding-top: 0;
  margin-top: 0;
}

h1 {
  font-size: 250%;
}

.nb--title {
  font-size: 0.9rem;
  color: #1a237e;
  text-transform: uppercase;
  font-weight: 700;
}

.login--container {
  height: 100vh;
  overflow: hidden;
}

.log--header {
  min-height: 50vh;
}

.sign--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  overflow: auto;

  .sign--content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #dbdbdb;
    height: 100%;
    width: 40%;
    .card {
      border: solid 1px #f2f2f2;
    }
  }
}

.primarycolor {
  color: #150fd1;
}

.loading_btn {
  cursor: not-allowed;
  filter: grayscale(0.55);

  &:hover {
    cursor: not-allowed !important;
    transform: none;
  }
}

.sign-left {
  width: 60%;
  height: 100%;
  background-color: #5e72e4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-event > input {
  border-radius: 24px !important;
  height: 40px !important;
}
.input-even > .el-input__suffix {
  border-radius: 24px;
  width: 40px;
  background-color: #5e98e4;
}

.keycloack--login {
  height: 100%;
  width: 100%;
}
</style>
